<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-body">




            <div class="row mb-2">
              <div class="col">
                <h4>Clinic Owner ({{owners.length}})</h4>
              </div>
            </div>
            <div class="row mb-3 px-2 py-3 mx-1 bg-light rounded shadow-sm">
              <div class="col-4 my-2" v-for="u in owners" v-if="u.billing_subscription_item">
                <div class="card">
                  <div class="card-img-top bg-primary p-2 text-center text-white">
                    <i class="far fa-3x mt-2" :class="u.billing_subscription_item.billing_plan.user_role | formatRoleAsIcon"></i>
                    <p class="text-capitalize mb-0 fw-bold">{{ u.name }}</p>
                    <p class="text-capitalize my-1">{{ u.billing_subscription_item.billing_plan.user_role }}</p>
                  </div>
                  <div class="card-body">
                    <div class="card-text text-center">
                      <p class="fw-bold m-0">£{{u.billing_subscription_item.billing_plan.price.toFixed(2)}}</p>
                      <p class="badge bg-primary mb-0">Active</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col">
                <h4>Clinic Practitioners ({{practitioners.length}})</h4>
              </div>
            </div>
            <div class="row mb-3 px-2 py-3 mx-1 bg-light rounded shadow-sm">
              <div class="col-4 my-2" v-for="u in practitioners" v-if="u.billing_subscription_item">
                <div class="card">
                  <div class="card-img-top bg-primary p-2 text-center text-white">
                    <button class="btn btn-light btn-sm"
                            style="position: absolute; top: 6px; right: 6px;"
                            @click="removeUserFromSubscription(u)"
                    >
                      <i class="far fa-trash text-danger"></i>
                    </button>
                    <i class="far fa-3x mt-2" :class="u.billing_subscription_item.billing_plan.user_role | formatRoleAsIcon"></i>
                    <p class="text-capitalize mb-0 fw-bold">{{ u.name }}</p>
                    <p class="text-capitalize my-1">{{ u.billing_subscription_item.billing_plan.user_role }}</p>
                  </div>
                  <div class="card-body">
                    <div class="card-text text-center">
                      <p class="fw-bold m-0">£{{u.billing_subscription_item.billing_plan.price.toFixed(2)}} / <span class="text-capitalize">{{u.billing_subscription_item.billing_plan.billing_period}}</span></p>
                      <p class="badge bg-primary mb-0">Active</p>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-4 my-2">
                <div class="card bg-primary text-white h-100 cursor-pointer" @click="goToUrl('/billing/seats/payment?selectedtype=practitioner')">
                  <div class="card-body p-2 text-center d-flex h-100">
                    <div class="row justify-content-center align-self-center w-100 mx-auto">
                      <div class="col mx-auto">
                        <i class="far fa-3x fa-plus-circle mt-2"></i>
                        <p class="text-capitalize mb-0 fw-bold">Add New Practitioner</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row mb-2">
              <div class="col">
                <h4>Clinic Administrators ({{administrators.length}})</h4>
              </div>
            </div>
            <div class="row mb-3 px-2 py-3 mx-1 bg-light rounded shadow-sm">
              <div class="col-4 my-2" v-for="u in administrators" v-if="u.billing_subscription_item">
                <div class="card">
                  <div class="card-img-top bg-primary p-2 text-center text-white">
                    <button class="btn btn-light btn-sm"
                            style="position: absolute; top: 6px; right: 6px;"
                            @click="removeUserFromSubscription(u)"
                    >
                      <i class="far fa-trash text-danger"></i>
                    </button>
                    <i class="far fa-3x mt-2" :class="u.billing_subscription_item.billing_plan.user_role | formatRoleAsIcon"></i>
                    <p class="text-capitalize mb-0 fw-bold">{{ u.name }}</p>
                    <p class="text-capitalize my-1">{{ u.billing_subscription_item.billing_plan.user_role }}</p>
                  </div>
                  <div class="card-body">
                    <div class="card-text text-center">
                      <p class="fw-bold m-0">£{{u.billing_subscription_item.billing_plan.price.toFixed(2)}} / <span class="text-capitalize">{{u.billing_subscription_item.billing_plan.billing_period}}</span></p>
                      <p class="badge bg-primary mb-0">Active</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 my-2">
                <div class="card bg-primary text-white h-100 cursor-pointer" @click="goToUrl('/billing/seats/payment?selectedtype=administrator')">
                  <div class="card-body p-2 text-center d-flex h-100">
                    <div class="row justify-content-center align-self-center w-100 mx-auto">
                      <div class="col mx-auto">
                        <i class="far fa-3x fa-plus-circle mt-2"></i>
                        <p class="text-capitalize mb-0 fw-bold">Add New Administrator</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingNav from "../partials/BillingNav";
export default {
  props: [],
  data(){
    return {
      loading: true,
      busy: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        role: "practitioner"
      },
      users: [],
    }
  },
  components: {
    BillingNav,
  },
  methods: {
    goToUrl(url){
      this.$router.push(url);
    },
    inviteUser() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/clinic/invite-practitioner", this.form).then(({ data }) => {
        this.form = {
          first_name: "",
          last_name: "",
          email: "",
          role: ""
        };
        this.$EventBus.$emit("alert", data);
        window.location = "/billing/seats";
      });
    },
    resendInvite(id) {
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/clinic/resend-invite-to-practitioner", { user_id: id }).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
      });
    },
    removeUserFromSubscription(user) {
      var confirmed = confirm('Are you sure you want to remove this user from your subscription? Their account will be disabled and they will no longer be able to access the platform.');

      if (confirmed) {
        this.busy = true;
        this.$axios.post(process.env.VUE_APP_API_URL + '/billing/remove-user', {
          user: user,
        }).then(({data}) => {
          this.$EventBus.$emit("alert", data);
          if (data.state === 'success') {
            setTimeout(() => {
              window.location = '/billing/seats';
            }, 3000);
          } else {
            this.busy = false;
          }
        });
      }
    },
    fetchClinicUsers() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/clinic/fetch-clinic-users").then(({ data }) => {
        this.users = data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.fetchClinicUsers();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatRoleAsIcon(role) {
      switch (role){
        case "practitioner":
          return "fa-user-md";
        case "owner":
          return "fa-user-crown";
        case "administrator":
          return "fa-user-cog"
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    practitioners() {
      if (!this.loading){
        return this.users.filter(function (u) {
          return u.status === 'active' && u.user_roles.includes('practitioner');
        });
      }
    },
    administrators() {
      if (!this.loading){
        return this.users.filter(function (u) {
          return u.status === 'active' && u.user_roles.includes('administrator');
        });
      }
    },
    owners() {
      if (!this.loading){
        return this.users.filter(function (u) {
          return u.status === 'active' && u.user_roles.includes('owner');
        });
      }
    }
  }
};
</script>

<style>
</style>
